import React, {Component} from 'react';
import {connect} from 'react-redux'
import axios from "axios";
import Auth from "../../libs/auth";
import BillingHistory from "./_refunds/BillingHistory";

axios.defaults.withCredentials = true;
const SERVER = process.env.API_URL + '/admin/customers';
const CUSTOMER = process.env.API_URL + '/admin/customers/customer_info/';

class Refunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: props.storeData.current_customer,
      isLoading: true,
      customerInfo: null,
      irsForms: [],
      gua: [],
      formTypes: {
        irs_8949: "IRS 8949",
        irs_schedule_d: "Schedule D",
        irs_stolen_lost: "Stolen or Lost",
        irs_schedule_b: "Schedule B",
        irs_schedule_1: "Schedule 1",
        turbo_tax_zip: "H&R Block/Turbo Tax",
        tax_act_csv: "Tax Act",
        irs_schedule_a: "Schedule A",
        irs_8949_zip: "RS 8949 Zip",
      }
    }
  }

  componentDidMount() {
    this.getCustomerInfo();
  }

  getCustomerInfo = () => {
    let config = {
      headers: Auth.getHeader()
    };
    axios.get(CUSTOMER + this.state.customerId, config)
      .then((response) => {
        this.setState({
          customerInfo: response.data.data,
          isLoading: false
        });
      })
      .catch((err) => {
        console.error(err.response);
        Auth.isUnauthorized(err.response.status);
      });
  }


  render() {
    const {
      isLoading
    } = this.state;
    const {customerInfo, customerId} = this.state;
    return (
      <div className="animated fadeIn">
        {isLoading ?
          <div className="preloader loader-small">
          </div>
          :
          <div>
            <div className="card">
              <div className="card-header">
                <i className="fas fa-edit"/> Refunds
                <div className="card-actions">
                  <a href="http://zenledger.io">
                    <small className="text-muted"></small>
                  </a>
                </div>
              </div>
              <div className="row px-3 mt-3">
                <div className='col-sm-11'>
                  <h5>{_.get(customerInfo, 'email')}</h5>
                </div>
                <div className="col-sm-1">
                  <button className="btn btn-outline-primary btn-sm" onClick={() => this.props.showTaxDetail()}>
                    Back
                  </button>
                </div>
              </div>
              <div>
                <BillingHistory customerId={customerId}/>
              </div>
            </div>
          </div>
        }
        <style jsx>{`
          .custom-select {
            width: 30%;
            min-width: 80px;
          }

          .subscription-form-td {
            max-width: 285px;
          }
        `}</style>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    showTaxDetail: (customer) => {
      dispatch({
        type: "ENABLE_CL",
        payload: customer
      })
    }
  };
};

export default connect(null, mapDispatchToProps)(Refunds);

export default {
  items: [
    {
      name: 'Customer',
      url: '/customer',
      icon: 'icon-user',
      badge: {
        variant: 'info',
      }
    },
  ]
};

import axios from "axios";
import Auth from "./auth";
import swal from 'sweetalert';
import Cookie from "js-cookie";

axios.defaults.withCredentials = true;
const IMPERSONATE_COOKIE = 'impersonating';

class CustomerMasquerade {
  static domain() {
    return document.domain.split('.').slice(-2).join('.');
  }

  static impersonate(userId) {
    let impersonateUrl = process.env.API_URL + '/users/' + userId + '/impersonate'
    axios.post(impersonateUrl).then(response => {
      Cookie.set(IMPERSONATE_COOKIE, 'true', { domain: CustomerMasquerade.domain() });
      if(response.data.roles.includes('cpa'))
        window.open(`${process.env.FRONTEND_URL}/tax_pro/dashboard`, '_blank');
      else
        window.open(process.env.FRONTEND_URL, '_blank');
    });
  }

  static impersonating() {
    return Cookie.get(IMPERSONATE_COOKIE) === 'true';
  }

  static stopImpersonating() {
    if(CustomerMasquerade.impersonating()) {
      let stopImpersonateUrl = process.env.API_URL + '/users/stop_impersonating';
      return axios.post(stopImpersonateUrl).then(response => {
        Cookie.remove(IMPERSONATE_COOKIE, { domain: CustomerMasquerade.domain() });
      });
    }
  }
}

export default CustomerMasquerade;

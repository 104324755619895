import React, { Component } from "react";
import Customer from "./Customer";
import ResetPassword from "./ResetPassword";
import LinkCPA from "./LinkCPA";
import { connect } from "react-redux";
import PlanDetail from "./PlanDetail";
import Downloads from "./Downloads";
import Refunds from "./Refunds";
import SourceDetail from "./SourceDetail";
import SourceApiResponses from "./SourceApiResponses";

class CustomerMain extends Component {
  constructor() {
    super();
  }

  render() {
    let customerList = this.props.storeData.customer_list_status;
    let resetPassword = this.props.storeData.reset_password_status;
    let taxCalcProgress = this.props.storeData.tax_calc_progress_status;
    let audit = this.props.taxData.tax_year_status;
    let linkCPA = this.props.storeData.link_cpa_status;
    let planDetail = this.props.storeData.plan_detail_status;
    let sourceDetail = this.props.storeData.source_detail_status;
    let downloadsStatus = this.props.storeData.downloads_status;
    let refundStatus = this.props.storeData.refunds_status;
    let showSourceApiResponses = this.props.storeData.show_source_api_responses;
    let wizard = null;
    if (customerList) {
      wizard = <Customer />;
    } else if (linkCPA) {
      wizard = <LinkCPA {...this.props} />;
    } else if (resetPassword) {
      wizard = <ResetPassword {...this.props} />;
    } else if (planDetail) {
      wizard = <PlanDetail {...this.props} />;
    } else if (sourceDetail) {
      wizard = <SourceDetail {...this.props} />;
    } else if (downloadsStatus) {
      wizard = <Downloads {...this.props} />;
    } else if (refundStatus) {
      wizard = <Refunds {...this.props} />;
    } else if (showSourceApiResponses) {
      wizard = <SourceApiResponses {...this.props} />;
    }
    return <div>{wizard}</div>;
  }
}
const storeData = state => {
  return {
    storeData: state.customerReducer,
    taxData: state.taxReducer
  };
};

export default connect(storeData)(CustomerMain);

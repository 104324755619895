const initialState = {
  listUsers: false,
  users: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LIST_USERS":
        state = {
            ...state,
            listUsers: true,
            users: action.payload.data
        };
        break;
  }
  return state;
}

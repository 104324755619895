import React, { Component } from 'react';
import {connect} from 'react-redux'
import axios from "axios";
import Auth from "../../libs/auth";
import { CopyToClipboard } from 'react-copy-to-clipboard';

axios.defaults.withCredentials = true;
const CUSTOMER = process.env.API_URL + '/admin/customers';
const SERVER = process.env.API_URL + '/admin/customers/reset_password';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId  : props.storeData.current_customer,
      customerinfo: [],
      password    : '',
      isLoading   : true,
      message     : null,
      copied      : false
    }
  }

  getCustomerInfo = () =>  {
    let config = {
      headers: Auth.getHeader()
    }
    axios.get(CUSTOMER + '/customer_info/' + this.state.customerId, config)
    .then((response) => {
      this.setState({
        customerinfo: response.data.data,
        isLoading: false
      })
    })
    .catch( (err) => {
      console.error(err.response);
      Auth.isUnauthorized(err.response.status);
    });

  }

  componentDidMount() {
    this.getCustomerInfo()
  }

  handleChange = (e) => {
    this.setState({ password: e.target.value });
  }

  generatePassword = () => {
    let randomstring = Math.random().toString(36).slice(-8);
    this.setState({password: randomstring})
  }

  savePassword = () => {
    const { customerId, password } = this.state;
    let data = {
      user_id: customerId,
      password: password
    }
    const config = {
      headers: Auth.getHeader()
    }
    if(password !== '')
      axios.post(SERVER, data, config).then(response => {
        this.setState({
          message: response.data.message
        });
        setTimeout(() => {
          this.setState({
            message: null
          })
        }, 5000);
        })
      .catch( (err) => {
        console.error(err.response);
        Auth.isUnauthorized(err.response.status);
      });
  }

  render() {
    const { isLoading, password, message } = this.state;
    let customerinfo = this.state.customerinfo;
    return (
      <div className="animated fadeIn">
        {isLoading ?
        <div className="preloader loader-small">
        </div>
          :
        <div>
          <div className="card">
            <div className="card-header">
              <i className="fas fa-edit" /> Reset Password
              <div className="card-actions">
                <a href="http://zenledger.io">
                  <small className="text-muted"></small>
                </a>
              </div>
            </div>
            <div className="row col-sm-12 mt-3">
                <div className='col-sm-11' />
                <div className="col-sm-1">
                  <button className="btn btn-outline-primary btn-sm" onClick={()=> this.props.showTaxDetail()}>
                    Back
                  </button>
                </div>
            </div>
            <div className="row col-sm-12 mt-3">
              {message &&
                <div className=" col-sm-12">
                <div className="alert alert-primary" role="alert">
                  {message}
                </div>
                </div>
              }
              <div className="row col-sm-12">
                <label className="col-sm-2">Email:</label>
                <div className="col-sm-10">{ _.get(customerinfo, 'email') }</div>
              </div>
              <div className="col-sm-12 row">
                <label className="col-sm-2">Password:</label>
                <div className="col-sm-10">
                  <div className="d-flex">
                    <input
                      type='text'
                      name='password'
                      id='password-box'
                      className='password-field'
                      placeholder='Password'
                      value={password}
                      onChange={this.handleChange} />
                    <div>
                      <CopyToClipboard text={password}
                        onCopy={() => {
                          this.setState({copied: true});
                          setTimeout(() => {this.setState({ copied: false })}, 5000);
                        }}>
                        <button className="btn btn-outline-primary btn-sm">
                          <i className="fas fa-clone" />
                        </button>
                      </CopyToClipboard>
                      {this.state.copied ? <span className="mx-2">Copied to clipboard.</span> : null}
                    </div>
                  </div>
                  <div className="my-2">
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={()=> this.generatePassword()}>Generate</button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <button
                  className="btn btn-outline-primary btn-sm my-3"
                  onClick={()=> this.savePassword()}>Save</button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      showTaxDetail: (customer) => {
          dispatch({
              type: "ENABLE_CL",
              payload: customer
          })
      }
  };
};

export default connect(null, mapDispatchToProps)(ResetPassword);

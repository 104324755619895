import axios from 'axios';
import Cookie from 'js-cookie';
import swal from 'sweetalert';

const SERVER = process.env.API_URL;
const LOGIN_COOKIE = 'logged_in';

axios.defaults.withCredentials = true;

class Auth {
  static domain() {
    return document.domain.split('.').slice(-2).join('.');
  }

  static login(email, password) {
    axios.post(SERVER + '/zl_login', {
      email: email,
      password: password
    }).then((response) => {
      let user = response.data.data;
      if (user.admin) {
        Cookie.set(LOGIN_COOKIE, 'true', { domain: Auth.domain() });
        window.location = '/';
      }
      else {
        window.location = '/'
        swal('Oops!', 'Invalid login credentials. Please try again.', 'error');
      }
    })
    .catch( (err) => {
      console.error(err.response);
      swal('Oops!', err.response.data.errors[0], 'error');
    });
  }

  static logout() {
    axios.delete(
      SERVER + '/auth/sign_out'
    ).then(_ => {
      Cookie.remove(LOGIN_COOKIE, { domain: Auth.domain() });
      window.location = '/';
    }).catch(_ => {
      Cookie.remove(LOGIN_COOKIE, { domain: Auth.domain() });
      window.location = '/';
    });
  }

  static isUnauthorized(status) {
    if (status == 401){
      Auth.logout();
    }
  }

  static isLoggedIn() {
    return Cookie.get(LOGIN_COOKIE) === 'true';
  }

  static getUser() {
    return JSON.parse(Cookie.get('z_a_user'));
  }

  static getHeader() {
    let header_data = {
      'token-type': 'Bearer'
    };
    return header_data;
  }
}

export default Auth;

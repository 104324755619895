import { LIST_EXCHANGE_API, ADD_EXCHANGE_API } from "../constants";

const exchangeApiReducer = (state = [], action) => {
    switch (action.type) {
        case LIST_EXCHANGE_API:
            state = {
                ...state
            };
            break;
        case ADD_EXCHANGE_API:
            state = {
                ...state
            };
            break;
    }
    return state;
};

export default exchangeApiReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Auth from "../../libs/auth";
import { Button } from "reactstrap";

axios.defaults.withCredentials = true;
const URL = process.env.API_URL + "/admin/customers";

class SourceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: props.storeData.current_customer,
      isLoading: true,
      customerinfo: [],
      userEmail: "",
      message: null,
      sourceData: [],
      selectedSources: []
    };
  }

  handleSourceChange = (e, id) => {
    let { selectedSources } = this.state;
    if (e.target.checked) {
      selectedSources.push(id);
    } else {
      selectedSources = selectedSources.filter(item => item !== id);
    }
    this.setState({ selectedSources: selectedSources });
  };

  componentDidMount() {
    this.getCustomerInfo();
    this.fetchSourceData();
  }

  fetchSourceData = () => {
    let config = {
      headers: Auth.getHeader()
    };
    axios
      .get(URL + `/source_detail/${this.state.customerId}`, config)
      .then(response => {
        this.setState({
          sourceData: response.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        console.error(err.response);
        Auth.isUnauthorized(err.response.status);
      });
  };

  getCustomerInfo = () => {
    let config = {
      headers: Auth.getHeader()
    };
    axios
      .get(URL + "/customer_info/" + this.state.customerId, config)
      .then(response => {
        this.setState({
          customerinfo: response.data.data
        });
      })
      .catch(err => {
        console.error(err.response);
        Auth.isUnauthorized(err.response.status);
      });
  };

  render() {
    const { isLoading, message, sourceData, selectedSources } = this.state;
    let customerinfo = this.state.customerinfo[0];
    return (
      <div className="animated fadeIn">
        {isLoading ? (
          <div className="preloader loader-small" />
        ) : (
          <div>
            <div className="card">
              <div className="card-header">
                <i className="fas fa-edit" /> Source Detail
              </div>
              <div className="card-body">
                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-11">
                          <h5>{_.get(customerinfo, "email")}</h5>
                        </div>
                        <div className="col-sm-1">
                          <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => this.props.showTaxDetail()}
                          >
                            Back
                          </button>
                        </div>
                        <div className="col-sm-12">
                          <b>List of sources</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {message && (
                      <div className="col-sm-12 mt-3">
                        <div className="alert alert-primary" role="alert">
                          {message}
                        </div>
                      </div>
                    )}
                    <div className="col-sm-12">
                      <div className="table-responsive">
                        <table className="table table-bordered striped">
                          <thead>
                            <tr>
                              <th />
                              <th>Source</th>
                              <th>Transaction Count</th>
                              <th>Coin List</th>
                              <th>Source Api Response Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sourceData.length > 0 ? (
                              sourceData.map(sub => {
                                return (
                                  <tr key={sub.id}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        value={sub.id}
                                        onChange={e =>
                                          this.handleSourceChange(e, sub.id)
                                        }
                                      />
                                    </td>
                                    <td style={{ maxWidth: "350px" }}>{sub.name}</td>
                                    <td>{sub.transaction_count}</td>
                                    <td style={{ maxWidth: "400px" }}>{sub.coins.map(s => s.code).join()}</td>
                                    <td>
                                      {sub.source_api_response_count > 0 ? (
                                        <Button
                                          className="btn btn-outline-primary btn-sm"
                                          onClick={() =>
                                            this.props.showSourceApiResponses(
                                              sub
                                            )
                                          }
                                        >
                                          {sub.source_api_response_count}
                                        </Button>
                                      ) : (
                                        sub.source_api_response_count
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="4">Detail not found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <style jsx>{`
          .custom-select {
            width: 25%;
          }
        `}</style>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showTaxDetail: customer => {
      dispatch({
        type: "ENABLE_CL",
        payload: customer
      });
    },
    showSourceApiResponses: source => {
      dispatch({
        type: "SHOW_SOURCE_API_RESPONSES",
        payload: source
      });
    }
  };
};

export default connect(null, mapDispatchToProps)(SourceDetail);

import React, { Component } from 'react';
import {connect} from 'react-redux';
import axios from "axios";
import Auth from "../../libs/auth";
import { CopyToClipboard } from 'react-copy-to-clipboard';

axios.defaults.withCredentials = true;
const CUSTOMER = process.env.API_URL + '/admin/customers/customer_info/';
const SERVER = process.env.API_URL + '/admin/customers/link_with_cpa';

class LinkCPA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId  : props.storeData.current_customer,
      customerinfo: [],
      cpa_email: '',
      isLoading   : true,
      message     : null,
      copied      : false
    };
  }

  getCustomerInfo = () =>  {
    let config = {
      headers: Auth.getHeader()
    };
    axios.get(CUSTOMER + this.state.customerId, config)
    .then((response) => {
      this.setState({
        customerinfo: response.data.data,
        isLoading: false
      });
    })
    .catch( (err) => {
      console.error(err.response);
      Auth.isUnauthorized(err.response.status);
    });

  }

  componentDidMount() {
    this.getCustomerInfo();
  }

  handleChange = (e) => {
    this.setState({cpa_email: e.target.value });
  }

  submitCpa = () => {
    const { customerId, cpa_email } = this.state;
    let data = {
      user_id: customerId,
      cpa_email: cpa_email
    };
    const config = {
      headers: Auth.getHeader()
    };
    if(cpa_email !== '')
      axios.post(SERVER, data, config).then(response => {
        this.setState({
          message: "Linked " + response.data.cpa_email + " & " + response.data.user_email
        });
        setTimeout(() => {
          this.setState({
            message: null
          });
        }, 5000);
      })
      .catch( (err) => {
        swal("Oops!", "CPA not found or user is not marked as a CPA");
        console.error(err.response);
        Auth.isUnauthorized(err.response.status);
      });
  }

  render() {
    const { isLoading, cpa_email, message } = this.state;
    let customerinfo = this.state.customerinfo;
    return (
      <div className="animated fadeIn">
        {isLoading ?
        <div className="preloader loader-small">
        </div>
          :
        <div>
          <div className="card">
            <div className="card-header">
              <i className="fas fa-edit" /> Link CPA
              <div className="card-actions">
                <a href="http://zenledger.io">
                  <small className="text-muted"></small>
                </a>
              </div>
            </div>
            <div className="row col-sm-12 mt-3">
                <div className='col-sm-11' />
                <div className="col-sm-1">
                  <button className="btn btn-outline-primary btn-sm" onClick={()=> this.props.showTaxDetail()}>
                    Back
                  </button>
                </div>
            </div>
            <div className="row col-sm-12 mt-3">
              {message &&
                <div className=" col-sm-12">
                <div className="alert alert-primary" role="alert">
                  {message}
                </div>
                </div>
              }
              <div className="row col-sm-12">
                <label className="col-sm-2">Email:</label>
                <div className="col-sm-10">{ _.get(customerinfo, 'email') }</div>
                <label className="col-sm-2">Customer's CPAs:</label>
                <div className="col-sm-10">{ _.get(customerinfo, 'customer_cpa') }</div>
              </div>

              <div className="col-sm-12 row m-y-3">
                <label className="col-sm-2">Link Customer to a CPA:</label>
                <div className="col-sm-10">
                  <div className="d-flex">

                    <input
                      type='text'
                      name='cpa_email'
                      id='cpa-email'
                      className='cpa-email'
                      placeholder='Cpa Email'
                      value={cpa_email}
                      onChange={this.handleChange} />
                  </div>

                </div>
              </div>
              <div className="col-sm-12">
                <button
                  className="btn btn-outline-primary btn-sm my-3"
                  onClick={()=> this.submitCpa()}>Save</button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      showTaxDetail: (customer) => {
        dispatch({
            type: "ENABLE_CL",
            payload: customer
        });
      }
  };
};

export default connect(null, mapDispatchToProps)(LinkCPA);

import dateFormat from 'dateformat';

export function dateFormatter (cell, row) {
    let d = new Date(cell);
    return dateFormat(d, "mm/dd/yyyy, h:MM TT");
}

export function datetimeFormatter (cell, row) {
  let d = new Date(cell);
  return dateFormat(d, "fullDate");
}

export function createdDateFormat (val) {
    let d = new Date(val);
    return dateFormat(d, "h:MM TT mmmm dS, yyyy");
}

export function userLastLogin (val) {
    let d = new Date(val);
    return dateFormat(d, "UTC:h:MM TT Z mmmm dS, yyyy");
}

export function yearList(){
  let year = [2010, 2012, 2013, 2014, 2015, 2016, 2017,2018, 2019, 2020, 2021,
              2022, 2023, 2024, 2025, 2026, 2027, 2028]
  return year
}

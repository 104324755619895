const initialState = {
    admin_view_status: true,
    current_admin: ''
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LIST_ADMIN":
            state = {
                ...state,
                admin_view_status: true,
                current_admin: action.payload
            };
            break;
        case "ADD_ADMIN":
            state = {
                ...state,
                admin_view_status: false
            };
            break;
        case "EDIT_ADMIN":
            state = {
                ...state,
                current_admin: action.payload
            };
            break;
    }
    return state;
};

export default adminReducer;

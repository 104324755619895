import { createStore, applyMiddleware, compose} from 'redux'
// import logger from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

import rootReducer from './reducers';

const initialState = {};
let enhancer = compose(applyMiddleware(thunk, promise()))
const enableDevTools = process.env.ENABLE_DEV_TOOLS;
// add redux only if redux devtools extension is installed
if (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) {
    enhancer = compose(
        applyMiddleware(thunk, promise()),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
}

export default createStore(rootReducer, initialState, enhancer);

const initialState = {
    admin_kpi_view_status: true,
};


const adminKpiReducer = (state = [], action) => {
    switch (action.type) {
        case "LIST_ADMIN_KPI":
            state = {
              ...state,
              admin_kpi_view_status: true
            };
            break;
    }
    return state;
};

export default adminKpiReducer;

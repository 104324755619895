import React from 'react';
import swal from 'sweetalert';
import Auth from "../../../libs/auth";
import axios from "axios";

const SERVER = process.env.API_URL + '/admin/customers';


export default class BillingHistory extends React.Component {
  state = {
    isLoading: false,
    isEmpty: false,
    billingHistoryObjs: [],
    recurringSubscribed: false,
    lastBillingYear: '',
  }

  componentDidMount() {
    this.fetchBillingHistory();
  }

  fetchBillingHistory() {
    let config = {
      headers: Auth.getHeader()
    };
    const {customerId} = this.props;
    axios.get(`${SERVER}/${customerId}/refunds`, config).then(response => {
      if (response.data.status) {
        this.setState({
          billingHistoryObjs: response.data.data.subscriptions,
          isLoading: false,
          recurringSubscribed: response.data.data.allow_recurring_payment,
          lastBillingYear: response.year,
        });
      } else {
        this.setState({
          isLoading: false,
          isEmpty: true,
          recurringSubscribed: response.data.data.allow_recurring_payment,
          lastBillingYear: response.year,
        });
      }
    });
  }

  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  onRefund = (payment) => {
    swal({
      title: "Are you sure?",
      text: `You are going to refund amount $${payment.amount}, This cannot be reverted!`,
      type: "warning",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false
    }).then((resp) => {
      if (resp) {
        const config = {
          headers: Auth.getHeader()
        };
        const data = {
          id: payment.payment_id,
        }
        axios.post(`${SERVER}/trigger_refunds`, data, config).then(response => {
          swal({
            title: 'Success',
            text: 'Payment refunded successfully',
            icon: 'success',
            closeOnEsc: true,
            closeOnClickOutside: false,
            buttons: {
              continue: {
                text: 'Continue',
                value: 'continue'
              }
            }
          });
          payment.refunded = true;
          this.setState({
            billingHistoryObjs: this.state.billingHistoryObjs
          });
        }).catch(err => {
          swal({
            title: 'Error',
            text: err.response.data.message,
            icon: 'error',
            closeOnEsc: true,
            closeOnClickOutside: false,
            type: 'error',
            buttons: {
              continue: {
                text: 'Continue',
                value: 'continue'
              }
            }
          });
        });
      } else {
        swal.close()
      }
    });
  }

  render() {
    const {billingHistoryObjs, isLoading, isEmpty} = this.state;
    const futureTaxYear = this.state.lastBillingYear;
    if (isLoading) return <div className="loader"/>;
    if (billingHistoryObjs.length > 0) {
      return (
        <div className="px-3 mt-3">
          <div className="row">
            <div className="col-md-12">
              <table className='table table striped'>
                <thead>
                <tr>
                  <th>Date</th>
                  <th>Subscribed Year</th>
                  <th>Description</th>
                  <th/>
                  <th>Payment Method</th>
                  <th>Amount</th>
                  <th>Refund</th>
                </tr>
                </thead>
                <tbody>
                {billingHistoryObjs.map(info => (
                  <tr key={info.payment_id}>
                    <td>{info.date}</td>
                    <td>{info.year}</td>
                    <td>{this.capitalize(info.description)}</td>
                    <td>{info.refunded && <span className="tag__pill">Refunded</span>}</td>
                    <td>{info.payment_method}</td>
                    <td>
                      ${info.amount}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => this.onRefund(info)}
                        disabled={info.refunded}
                      >
                        Refund
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <style jsx>{`
            .data-table {
              width: 100%;
            }

            table,
            th,
            td {
              border-bottom: 1px solid #e1e1e1;
              border-collapse: collapse;
            }

            th,
            td {
              padding: 0.75rem;
            }

            .success-sub {
              color: #00ab84;
              background: #fff;
              padding: 8px;
              border-radius: 6px;
            }

            .btn-disable-sub {
              background: #f8eeee;
              color: #e37775 !important;
              border: 0px;
              font-size: 0.875rem;
              padding: 7px 50px !important;
              font-weight: 500;
              width: auto;
              margin-right: 12px;
            }

            .btn-disable-sub:hover {
              background: #facac7 !important;
            }

            .btn-enable-sub {
              background: #e8f6f2;
              color: #00ab84 !important;
              border: 0px;
              font-weight: 500;
              width: auto;
              font-size: 0.875rem;
              padding: 7px 50px !important;
              margin-right: 12px;
            }

            .btn-enable-sub:hover {
              background: #b9f2e5 !important;
            }

            .subscribe-btn-wrapper {
              text-align: right;
            }

            .tag__pill {
              background: var(--purple);
              padding: 1px 5px;
              border-radius: 4px;
              color: var(--white);
              font-size: var(--font-xxs);
              font-weight: var(--weight-bold);
              text-transform: none;
              margin-left: 4px;
            }

            @media only screen and (max-width: 767px) {
              :global(.subscribe-btn-wrapper button) {
                padding: 7px 10px !important;
              }

              :global(.mobile-w-50) {
                width: 50% !important;
              }
            }
          `}</style>
        </div>
      );
    } else if (isEmpty) {
      return (
        <div className="px-3 mt-3">
          <h6>Customer have not subscribed to a ZenLedger plan.</h6>
        </div>
      );
    } else return <div className="preloader loader-small"></div>;
  }
}

const initialState = {
    exchanges: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "LIST_REQUESTED_EXCHANGES":
            state = {
                ...state,
                exchanges: action.payload.data
            };
            break;
    }
    return state;
}


const routes = {
  '/': 'Home',
  // '/dashboard': 'Dashboard',
  '/customer': 'CustomerMain',
  '/adminmain': 'AdminMain',
  '/exchange': 'ExchangeMain',
  '/publicApi': 'PublicApi',
  '/adminkpi' : 'AdminKpi',
  '/coinranking': 'CoinMain',
  '/heavy_usages': 'TrackHeavyUsage',
  '/inprogessTC': 'InprogressCalculationMain'
};
export default routes;

const initialState = {
  messages: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "DISPLAY_ERRORS":
      const errors = action.payload;
      state = {
        ...state,
        messages: {
          type: "error",
          content: errors[Object.keys(errors)[0]]
        }
      };
      break;
  }
  return state;
}

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import swal from "sweetalert";
import { connect } from "react-redux";
import axios from "axios";
import Auth from "../../libs/auth";
import CustomerMasquerade from "../../libs/customerMasquerade";
import _ from "lodash";
import * as DateFormat from "../../generics/helper/dateFormatter";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import Toggle from "react-toggle";
import "react-toggle/style.css";
const FileDownload = require("react-file-download");
const CUSTOMER = process.env.API_URL + "/admin/customers"; //change with env needed

axios.defaults.withCredentials = true;

const wizardStatus = {
  1: "✔",
  0: "x"
};

const cellEditProp = {
  mode: "click",
  blurToSave: true,
  beforeSaveCell: onBeforeSaveCell
};

function enumFormatter(cell, row, enumObject) {
  return enumObject[cell];
}

function onBeforeSaveCell(row, CellName, cellValue) {
  return true;
}

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParams: "",
      customerlist: [],
      isLoading: true,
      disableDownload: null,
      pageNo: 1,
      sizePerPage: 10,
      freeYear: [],
      currentCustomer: {},
      Years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
      value: "",
      searchProgressClass: null
    };
  }

  getCustomerAllInfo = () => {
    let config = { headers: Auth.getHeader() };

    axios
      .get(
        CUSTOMER +
          "/index/" +
          this.state.pageNo +
          "/" +
          this.state.sizePerPage +
          "?search_params=" +
          encodeURIComponent(this.state.searchParams),
        config
      )
      .then(response => {
        this.setState({
          customerlist: response.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  masqueradeCustomer = userId => {
    CustomerMasquerade.impersonate(userId);
    this.showMasqueradeAlert(false);
  };

  showMasqueradeAlert = reload => {
    swal({
      title: "You are logged in as customer.",
      text: "Would you like to exit and return to admin?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(yes => {
      if (yes)
        CustomerMasquerade.stopImpersonating().then(
          () => reload && window.location.reload(false)
        );
    });
  };

  componentDidMount() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("q");
    this.deleteToken();
    if (CustomerMasquerade.impersonating()) {
      this.showMasqueradeAlert(true);
      return;
    }
    this.setState(
      {
        searchParams: decodeURIComponent(
          myParam || this.props.storeData.search_params
        )
      },
      () => {
        this.getCustomerAllInfo();
      }
    );
  }

  searchCustomer = searchParams => {
    let config = { headers: Auth.getHeader() };

    this.setState({ searchProgressClass: "fas fa-spinner fa-spin fa-lg" });
    axios
      .get(
        CUSTOMER +
          "/index/1/" +
          this.state.sizePerPage +
          "?search_params=" +
          searchParams,
        config
      )
      .then(response => {
        this.setState({ searchProgressClass: null });
        this.setState({
          customerlist: response.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        console.error(err.response);
        Auth.isUnauthorized(err.response.status);
      });
  };

  setSearchParams = event => {
    event.preventDefault();
    this.setState({ searchParams: event.target.value });
  };

  handleKeyPress = event => {
    let search = event.target.value;
    const { history } = this.props;
    if (event.key === "Enter") {
      this.searchCustomer(encodeURIComponent(search));
      this.props.updateSearchParam(encodeURIComponent(search));
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("q", search);
      history.replace({
        search: searchParams.toString()
      });
    }
  };

  actionButtons = (cell, row, enumObject, index) => {
    const customer = JSON.stringify(row);
    return (
      <div>
        <button
          className="btn btn-outline-primary btn-sm"
          title="Masquerade User"
          value={row.id}
          onClick={() => this.masqueradeCustomer(row.id)}
        >
          <i className="fa-solid fa-circle-down" />
        </button>{" "}
        &nbsp;
        <button
          className="btn btn-outline-info btn-sm"
          title="Reset Password"
          onClick={() => this.props.showResetPassword(cell)}
        >
          <i className="fa-solid fa-lock" aria-hidden="true" />
        </button>
        <button
          className="btn btn-outline-info btn-sm mx-2"
          onClick={() => this.props.showLinkCPA(cell)}
        >
          Link CPA
        </button>
        <div className="second-row">
          <button
            className="btn btn-outline-info btn-sm mx-2"
            onClick={() => this.props.showPlanDetail(cell)}
          >
            Plan
          </button>
          <button
            className="btn btn-outline-info btn-sm"
            title="Re-run GUA"
            onClick={() => this.rerunGUA(cell)}
          >
            Rerun GUA
          </button>
        </div>
        <div className="second-row">
          <button
            className="btn btn-outline-info btn-sm"
            title="Reset Group Transaction"
            onClick={() => this.resetGT(cell)}
          >
            Reset GT
          </button>
          <button
            className="btn btn-outline-info btn-sm mx-2"
            title="Downloads"
            onClick={() => this.props.showDownloads(cell)}
          >
            Downloads
          </button>
        </div>
        <div className="third-row mt-2">
          <button
            className="btn btn-outline-info btn-sm"
            title="Refunds"
            onClick={() => this.props.showRefunds(cell)}
          >
            Refunds
          </button>
          <button
            className="btn btn-outline-info btn-sm mx-2"
            title="Refunds"
            onClick={() => this.recalculateHoldings(cell)}
          >
            Reset holdings
          </button>
        </div>
        {row.locked && (
          <div className="mt-2">
            <button
              title="Unlock user"
              className="btn btn-outline-info btn-sm"
              onClick={() => this.confirmUnlock(cell)}
            >
              Unlock user
            </button>
          </div>
        )}
        <style jsx>{`
          .second-row {
            padding-top: 10px;
          }
          .second-row button:first-child {
            margin-left: 0 !important;
          }
        `}</style>
      </div>
    );
  };

  sourceDetail = (cell, row) => {
    return (
      <div>
        <button
          className="btn btn-outline-info btn-sm"
          title="Source Detail"
          onClick={() => this.props.showSourceDetail(cell)}
        >
          <i className="fas fa-info-circle" aria-hidden="true" />
        </button>{" "}
        &nbsp;
      </div>
    );
  };

  downloadCustomerCsvFiles = (cell, row, index) => {
    this.setState({ disableDownload: index });
    const config = {
      headers: Auth.getHeader(),
      responseType: "arraybuffer"
    };
    axios
      .get(CUSTOMER + "/" + cell + "/user_csv_files", config)
      .then(response => {
        this.setState({ disableDownload: null });
        FileDownload(response.data, row.email + ".zip", "application/zip");
        axios.delete(CUSTOMER + "/" + cell + "/remove_zip_file", config);
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  downloadButton = (cell, row, enumObject, index) => {
    const { disableDownload } = this.state;
    const disable = disableDownload && disableDownload === index;
    return (
      <div>
        {(row.csv_files || row.wallet_files) && (
          <i
            title="Downlod Files"
            className={`fas fa-download ${
              disable ? "disable-download-btn" : "enable-download-btn"
            }`}
            onClick={() =>
              !disable && this.downloadCustomerCsvFiles(cell, row, index)
            }
            aria-hidden="true"
          />
        )}
      </div>
    );
  };

  freeCheckBox = (cell, row, enumObject, index) => {
    return (
      <label>
        <input
          type="checkbox"
          className="btn btn-outline-info btn-sm"
          onChange={() => this.toggleFree(!row.free, row.id)}
          defaultChecked={row.free}
        />{" "}
        Free All Year
      </label>
    );
  };

  yearChanged = (row, e) => {
    const id = row.id;
    let config = { headers: Auth.getHeader() };

    axios
      .patch(
        CUSTOMER + "/" + id + "/free_tax_year",
        { id: row.id, tax_year: e },
        config
      )
      .then(response => {
        this.getCustomerAllInfo();
        swal({
          title: "Free Tax Year switched",
          text: "Tax year switching completed",
          icon: "success",
          closeOnEsc: false,
          closeOnClickOutside: false,
          buttons: {
            continue: {
              text: "Continue",
              value: "continue"
            }
          }
        });
      });
  };

  renderYearSelect = (cell, row, enumObject, index) => {
    let currentYear = new Date().getFullYear();
    let checkBoxes = [];
    let i = 0;
    for (let year = currentYear; year >= 2013; year--) {
      i++;
      checkBoxes.push(
        <label>
          <Checkbox label={year} value={year} /> {year}
        </label>
      );
      if (i == 5) {
        checkBoxes.push(<br />);
        i = 0;
      }
    }
    return (
      <CheckboxGroup
        className="checkboxgroup"
        name="year"
        checkboxDepth={2}
        value={row.free_years}
        onChange={e => this.setState({ taxYear: e }, this.yearChanged(row, e))}
      >
        {checkBoxes}
        {this.freeCheckBox(cell, row, enumObject, index)}
      </CheckboxGroup>
    );
  };

  renderUserRoleRadio = (cell, row, enumObject, index) => {
    return (
      <div>
        {row.admin && <div>Admin</div>}
        {!row.admin && (
          <div>
            <label className="radio-label">
              <input
                name={`userRole${row.id}`}
                type="radio"
                value="customer"
                className="btn btn-outline-info btn-sm"
                onChange={e => this.toggleUserRole(e, row.id)}
                defaultChecked={row.role == "customer"}
              />{" "}
              Customer
            </label>
            <br />
            <label className="radio-label">
              <input
                name={`userRole${row.id}`}
                type="radio"
                value="cpa"
                className="btn btn-outline-info btn-sm"
                onChange={e => this.toggleUserRole(e, row.id)}
                defaultChecked={row.role == "cpa"}
              />{" "}
              CPA
            </label>
          </div>
        )}
      </div>
    );
  };

  renderProvider = (_cell, row) => {
    const provider = _.upperFirst(row.provider.replace(/_/g, " "));
    return <span key={row.id}>{provider}</span>;
  };

  toggleUserRole = (e, id) => {
    let config = { headers: Auth.getHeader() };

    axios
      .post(
        `${CUSTOMER}/toggle_user_role`,
        { id: id, role: e.target.value },
        config
      )
      .then(response => {
        if (response.data.status == "error") {
          swal("Oops!", response.data.message, "error");
        } else {
          swal("", response.data.message, "success");
        }
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  skipCaptchaSwitch = (cell, row, enumObject, index) => {
    return (
      <div>
        <Toggle
          defaultChecked={row.skip_robot_test}
          onChange={() => this.toggleSkipRobotTest(row.id)}
        />
      </div>
    );
  };

  toggleUserRole = (e, id) => {
    let config = { headers: Auth.getHeader() };

    axios
      .post(
        `${CUSTOMER}/toggle_user_role`,
        { id: id, role: e.target.value },
        config
      )
      .then(response => {
        if (response.data.status == "error") {
          swal("Oops!", response.data.message, "error");
        } else {
          swal("", response.data.message, "success");
        }
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  toggleDisableMfaCheckbox = (cell, row, enumObject, index) => {
    return (
      <div>
        {row.otp_enabled && (
          <button
            className="btn btn-outline-info btn-sm mx-2"
            onClick={() => this.handleDisableMfaClick(row)}
          >
            Disable
          </button>
        )}
      </div>
    );
  };

  handleDisableMfaClick = user => {
    let config = { headers: Auth.getHeader() };

    axios
      .post(`${CUSTOMER}/disable_user_mfa`, { user_id: user.id }, config)
      .then(response => {
        if (response.data.status == "error") {
          swal("Ooops!", response.data.message, "error");
        } else {
          swal("", response.data.message, "success").then(value => {
            window.location.reload();
          });
        }
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  toggleSkipRobotTest = id => {
    let config = { headers: Auth.getHeader() };

    axios
      .patch(CUSTOMER + "/" + id + "/skip_robot_test", { id: id }, config)
      .then(response => {
        if (response.data.status == "error") {
          swal("Oops!", response.data.message, "error");
        } else {
          swal("", response.data.message, "success");
        }
        this.getCustomerAllInfo();
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  toggleFree = (val, id) => {
    let config = { headers: Auth.getHeader() };

    axios
      .patch(CUSTOMER + "/" + id, { id: id, free: val }, config)
      .then(response => {
        this.getCustomerAllInfo();
        swal({
          title: "Free Tax Year switched",
          text: "Tax year switching completed",
          icon: "success",
          closeOnEsc: false,
          closeOnClickOutside: false,
          buttons: {
            continue: {
              text: "Continue",
              value: "continue"
            }
          }
        }).then(() => {});
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  deleteToken = () => {
    const request = new URLSearchParams(location.search);
    const code = request.get("code");
    const c_id = request.get("c_id");
    if (code) {
      CustomerMasquerade.stopImpersonating();
      setTimeout(function() {
        window.location = "/customer";
      }, 2000);
    }
  };

  rerunGUA = id => {
    let config = { headers: Auth.getHeader() };

    axios
      .patch(CUSTOMER + "/" + id + "/rerun_gua", {}, config)
      .then(response => {
        if (response.data.status === true) {
          swal({
            title: "GUA rerun",
            text: response.data.message,
            icon: "success",
            closeOnEsc: true,
            closeOnClickOutside: false,
            buttons: {
              continue: {
                text: "Continue",
                value: "continue"
              }
            }
          });
        }
      });
  };

  recalculateHoldings = id => {
    let config = { headers: Auth.getHeader() };

    axios
      .post(CUSTOMER + "/" + id + "/recalculate_holdings", {}, config)
      .then(response => {
        if (response.data.status === true) {
          swal({
            title: "Holding recalculation triggered",
            text: response.data.message,
            icon: "success",
            closeOnEsc: true,
            closeOnClickOutside: false,
            buttons: {
              continue: {
                text: "Continue",
                value: "continue"
              }
            }
          });
        }
      });
  };

  resetGT = id => {
    let config = { headers: Auth.getHeader() };

    axios
      .get(CUSTOMER + "/" + id + "/reset_grouped_transaction", config)
      .then(response => {
        if (response.data.status === "success") {
          swal({
            title: "Reset user grouped transactions",
            text: "Job initialized for group transaction reset",
            icon: "success",
            closeOnEsc: true,
            closeOnClickOutside: false,
            buttons: {
              continue: {
                text: "Continue",
                value: "continue"
              }
            }
          });
        }
      });
  };

  confirmUnlock = id => {
    swal({
      title: "Unlock User",
      text: "Are you sure you want to unlock this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(yes => {
      if (yes) this.unlockUser(id);
    });
  };

  unlockUser = id => {
    const config = { headers: Auth.getHeader() };

    axios
      .patch(CUSTOMER + "/" + id + "/unlock", {}, config)
      .then(response => {
        if (response.data.status === "success") {
          swal({
            text: response.data.message,
            icon: "success",
            closeOnEsc: false,
            closeOnClickOutside: false,
            buttons: {
              continue: {
                text: "Continue",
                value: "continue"
              }
            }
          }).then(yes => {
            if (yes) window.location.reload(false);
          });
        }
      })
      .catch(_error => {
        swal("", "Failed to unlock user", "error");
      });
  };

  render() {
    const { searchParams, customerlist, isLoading } = this.state;
    return (
      <div className="animated fadeIn">
        {isLoading ? (
          <div className="preloader loader-small" />
        ) : (
          <div className="card">
            <div className="card-header">
              <i className="fas fa-edit" /> Customers
              <div className="card-actions">
                <a href="http://zenledger.io">
                  <small className="text-muted" />
                </a>
              </div>
            </div>
            <div className="card-body">
              <div style={{ paddingBottom: "10px" }}>
                <input
                  autoFocus
                  style={{ width: 300, height: 40, fontSize: 18 }}
                  type="text"
                  name="search-box"
                  id="search-box"
                  value={this.state.searchParams || ""}
                  onChange={this.setSearchParams}
                  className="search-field"
                  placeholder="Search"
                  onKeyUp={this.handleKeyPress}
                />
                <i
                  className={this.state.searchProgressClass}
                  style={{ marginLeft: 5 }}
                />
              </div>
              <div>
                <BootstrapTable
                  ref="table"
                  bodyStyle={{ overflow: "overlay" }}
                  data={customerlist}
                  remote={true}
                  cellEdit={cellEditProp}
                  version="4"
                  striped
                  hover
                >
                  <TableHeaderColumn
                    editable={false}
                    width="250"
                    dataField="email"
                    title="email"
                    isKey
                  >
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="200"
                    dataField="id"
                    dataFormat={this.actionButtons}
                  >
                    Action
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="320"
                    dataField="uuid"
                    title="uuid"
                  >
                    UUID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="60"
                    formatExtraData={wizardStatus}
                    dataFormat={enumFormatter}
                    dataField="paid"
                  >
                    Paid
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="350"
                    dataField="value"
                    dataFormat={this.renderYearSelect}
                  >
                    Make Free Year
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="120"
                    dataField="skip_robot_test"
                    dataFormat={this.skipCaptchaSwitch}
                  >
                    Skip Captcha
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="70"
                    dataField="id"
                    dataFormat={this.downloadButton}
                  >
                    Files
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="90"
                    dataField="id"
                    dataFormat={this.sourceDetail}
                  >
                    Sources
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="110"
                    dataField="value"
                    dataFormat={this.renderUserRoleRadio}
                  >
                    User Role
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="80"
                    dataField="value"
                    dataFormat={this.toggleDisableMfaCheckbox}
                  >
                    2FA
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="180"
                    dataField="created_at"
                    dataFormat={DateFormat.dateFormatter}
                  >
                    Created
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="180"
                    dataField="last_sign_in_at"
                    dataFormat={DateFormat.dateFormatter}
                  >
                    Last logged in
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    editable={false}
                    width="140"
                    dataField="provider"
                    dataFormat={this.renderProvider}
                  >
                    Signup Type
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
              <span>
                #1 Create account. #2 Finish upload. #3 Pay. #4 Open Tax
                Results. #5 Mark All finished.
              </span>
            </div>
          </div>
        )}
        <style jsx>{`
          table > tbody > tr > td:nth-child(1),
          table > thead > tr:nth-child(2) > th:nth-child(1) {
            background-color: #20a8d8;
            position: sticky;
            position: -webkit-sticky;
            left: 0;
            z-index: 1;
          }
          .react-toggle-track-check,
          .react-toggle-track-x {
            top: 0px !important;
          }
          .btn:hover {
            cursor: pointer;
          }
          .checkboxgroup label {
            cursor: pointer;
            margin: 5px;
          }
          label.radio-label:hover {
            cursor: pointer;
          }
        `}</style>
      </div>
    );
  }
}

const storeData = state => {
  return {
    storeData: state.customerReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showResetPassword: customer => {
      dispatch({
        type: "RESET_PASSWORD",
        payload: customer
      });
    },
    showLinkCPA: customer => {
      dispatch({
        type: "LINK_CPA",
        payload: customer
      });
    },
    showDownloads: customer => {
      dispatch({
        type: "DOWNLOADS",
        payload: customer
      });
    },
    showRefunds: customer => {
      dispatch({
        type: "REFUNDS",
        payload: customer
      });
    },
    showPlanDetail: customer => {
      dispatch({
        type: "PLAN_DETAIL",
        payload: customer
      });
    },
    showSourceDetail: customer => {
      dispatch({
        type: "SOURCE_DETAIL",
        payload: customer
      });
    },
    updateSearchParam: searchParams => {
      dispatch({
        type: "UPDATE_SEARCH_PARAM",
        payload: searchParams
      });
    }
  };
};

export default connect(storeData, mapDispatchToProps)(withRouter(Customer));

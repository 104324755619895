const initialState = {
    exchange_view_status: true,
};

const exchangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LIST_EXCHANGE":
            state = {
                ...state,
                exchange_view_status: true
            };
            break;
    }
    return state;
};

export default exchangeReducer;

import React, {Component} from 'react';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import Header from '../../components/Header/';
import Sidebar from '../../components/Sidebar/';
import Breadcrumb from '../../components/Breadcrumb/';
import Aside from '../../components/Aside/';
import Footer from '../../components/Footer/';
import CustomerMain from '../../views/Customer/CustomerMain';
import Login from '../../views/Login/';
import Auth from "../../libs/auth";

class Full extends Component {
  constructor() {
    super();
    this.state = {
      'isLoggedIn': Auth.isLoggedIn()
    };
  }
  render() {
    const isLoggedIn = this.state.isLoggedIn;
    return (
      <div className="app">
      <Header />
      {isLoggedIn ? (
        <div className="app-body">
            <Sidebar {...this.props}/>
            <main className="main">
              <Breadcrumb className='mb-0'/>
              <Container fluid className='pl-0 p-0'>
                <Switch>
                  <Route path="/customer" name="Customer" component={CustomerMain}/>
                  <Redirect from="/" to="/customer"/>
                </Switch>
              </Container>
            </main>
            <Aside />
          </div>
      ) : (
        <div>
        <Switch>
            <Redirect from="/" to="/login"/>
            <Route path="/login" name="Dashboard" component={Login}/>
        </Switch>
        <Login />
        </div>
      )}
        <Footer />
      </div>
    );
  }
}

export default Full;

const initialState = {
    listFreeExchanges: true,
    exchanges: null,
    exchange: null,
    formAction: null,
    messages: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "LIST_FREE_ACCOUNTS_DOMAINS":
            state = {
                ...state,
                listFreeExchanges: true,
                exchanges: action.payload.data
            };
            break;
        case "ADD_FREE_ACCOUNTS_DOMAIN":
            state = {
                ...state,
                listFreeExchanges:false,
                formAction: 'new'
            };
            break;
        case "ADD_NEW_ACCOUNT_TO_LIST":
            const newAccount = action.payload;
            state = {
                ...state,
                listFreeExchanges: true,
                messages: {
                    type: 'success',
                    content: "New account added."
                }
            };
        break;
        case "EDIT_FREE_ACCOUNTS_DOMAIN":
            state = {
                ...state,
                listFreeExchanges: false,
                formAction: "edit",
                exchange: action.payload.data
            };
            break;
        case "UPDATE_FREE_ACCOUNTS_DOMAIN":
            const newData = action.payload.data;
            state = {
                ...state,
                listFreeExchanges: true,
                exchanges: state.exchanges.map(exchange => {
                    return exchange.id == newData.id ? newData : exchange;
                }),
                messages: {
                    type: 'success',
                    content: action.payload.message
                }
            };
        break
        case "DELETE_FREE_ACCOUNTS_DOMAIN":
            state = {
                ...state,
                exchanges: state.exchanges.filter(exchange => exchange.id !== action.payload),
                listFreeExchanges: true,
                messages: {
                    type: 'success',
                    content: "Free vendor account deleted."
                }
            }
        break;
    }
    return state;
}


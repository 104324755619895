import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <a href="https://zenledger.io" target='_blank'>Tax</a> &copy; { new Date().getFullYear() } ZenLedger.io
        <span className="float-right">By <a href="https://zenledger.io">ZenLedger</a></span>
      </footer>
    );
  }
}

export default Footer;

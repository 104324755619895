import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import { Provider } from 'react-redux'

// Styles
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../scss/style.scss'

// Containers
import Full from './containers/Full/'
import store from './store'

const history = createBrowserHistory();

ReactDOM.render((
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/" name="Home" component={Full}/>
      </Switch>
    </Router>
  </Provider>
), document.getElementById('root'));

import { } from '../constants';

const initialState = {
  exchangeMetric: false,
  isLoading: true
};

const exchangeMetricReducer = (state = initialState, action) => {
  switch(action.type) {
  }
  return state;
};

export default exchangeMetricReducer;

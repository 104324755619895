const routerBaseState = {
  customer_list_status: false,
  reset_password_status: false,
  tax_calc_progress_status: false,
  link_cpa_status: false,
  unblock_user_status: false,
  plan_detail_status: false,
  downloads_status: false,
  refunds_status: false,
  source_detail_status: false,
  show_source_api_responses: false
};

const routerState = key => ({
  ...routerBaseState,
  [key]: true
});

const initialState = {
  ...routerState("customer_list_status"),
  search_params: "",
  current_customer: ""
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISABLE_CL":
      state = {
        ...state,
        ...routerBaseState,
        current_customer: action.payload
      };
      break;
    case "ENABLE_CL":
      state = {
        ...state,
        ...routerState("customer_list_status")
      };
      break;
    case "SET_CUSTOMER":
      state = {
        ...state,
        ...routerBaseState,
        current_customer: action.payload,
        source_api_response_id: null
      };
      break;
    case "RESET_PASSWORD":
      state = {
        ...state,
        ...routerState("reset_password_status"),
        current_customer: action.payload,
        reset_password_status: true
      };
      break;
    case "LINK_CPA":
      state = {
        ...state,
        ...routerState("link_cpa_status"),
        current_customer: action.payload
      };
      break;

    case "TAX_CALC_PROGRESS":
      state = {
        ...state,
        ...routerState("tax_calc_progress_status"),
        current_customer: action.payload
      };
      break;
    case "UNBLOCK_USER":
      state = {
        ...state,
        ...routerState("unblock_user_status"),
        current_customer: action.payload
      };
      break;
    case "PLAN_DETAIL":
      state = {
        ...state,
        ...routerState("plan_detail_status"),
        current_customer: action.payload
      };
      break;
    case "DOWNLOADS":
      state = {
        ...state,
        ...routerState("downloads_status"),
        current_customer: action.payload
      };
      break;
    case "REFUNDS":
      state = {
        ...state,
        ...routerState("refunds_status"),
        current_customer: action.payload
      };
      break;
    case "UPDATE_SEARCH_PARAM":
      state = {
        ...state,
        search_params: action.payload
      };
      break;
    case "SOURCE_DETAIL":
      state = {
        ...state,
        ...routerState("source_detail_status"),
        current_customer: action.payload
      };
      break;
    case "SHOW_SOURCE_API_RESPONSES":
      state = {
        ...state,
        ...routerState("show_source_api_responses"),
        source: action.payload
      };
      break;
  }
  return state;
};

export default customerReducer;

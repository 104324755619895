import React from "react";
import { Route, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import routes from "../../routes";

const findRouteName = url => routes[url];

const getPaths = pathname => {
  const paths = ["/"];

  if (pathname === "/") return paths;

  pathname.split("/").reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const BreadcrumbsItem = ({ match, clearRouteState }) => {
  const routeName = findRouteName(match.url);
  if (routeName) {
    return match.isExact ? (
      <BreadcrumbItem active>{routeName}</BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link
          to={match.url || ""}
          onClick={() => routeName === "Home" && clearRouteState()}
        >
          {routeName}
        </Link>
      </BreadcrumbItem>
    );
  }
  return null;
};

const Breadcrumbs = ({ location: { pathname }, clearRouteState }) => {
  const paths = getPaths(pathname);
  const items = paths.map((path, i) => (
    <Route
      key={i++}
      path={path}
      render={props => (
        <BreadcrumbsItem {...props} clearRouteState={clearRouteState} />
      )}
    />
  ));
  return <Breadcrumb>{items}</Breadcrumb>;
};

const AllBreadCrumbs = ({ clearRouteState }) => (
  <div>
    <Route
      path="/:path"
      render={props => (
        <Breadcrumbs {...props} clearRouteState={clearRouteState} />
      )}
    />
  </div>
);

const mapDispatchToProps = dispatch => ({
  clearRouteState: () => dispatch({ type: "ENABLE_CL" })
});

export default connect(null, mapDispatchToProps)(AllBreadCrumbs);

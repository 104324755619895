import { combineReducers } from 'redux';

import customerReducer from './customerReducer';
import taxReducer from './taxReducer';
import adminReducer from './adminReducer';
import adminKpiReducer from './adminKpiReducer';
import exchangeReducer from './exchangeReducer';
import exchangeMetricReducer from './exchangeMetricReducer';
import exchangeApiReducer from './exchangeApiReducer';
import freeAccountsDomainReducer from './freeAccountsDomainReducer';
import freeAccountsDomainUserReducer from './freeAccountsDomainUserReducer';
import requestedExchangeReducer from './requestedExchangeReducer';
import errorReducer from './errorReducer';
import {reducer as formReducer} from 'redux-form';

const rootReducer = combineReducers({
    exchangeMetricReducer,
    customerReducer,
    taxReducer,
    adminReducer,
    exchangeReducer,
    exchangeApiReducer,
    adminKpiReducer,
    form:formReducer,
    freeAccountsDomainReducer,
    freeAccountsDomainUserReducer,
    errorReducer,
    requestedExchangeReducer
});

export default rootReducer;

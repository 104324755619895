const initialState = {
    tax_year_status: false,
    current_tax: ''
};

const taxReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DISABLE_TY":
            state = {
                ...state,
                tax_year_status: false,
                current_tax: ''
            };
            break;
        case "ENABLE_TY":
            state = {
                ...state,
                tax_year_status: true,
                current_tax: action.payload
            };
            break;
        case "SET_TAX":
            state = {
                ...state,
                current_tax: action.payload
            };
            break;
    }
    return state;
};

export default taxReducer;

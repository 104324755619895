import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Auth from "../../libs/auth";

axios.defaults.withCredentials = true;
const URL = process.env.API_URL + "/admin";

const SourceApiResponses = ({
  storeData: { current_customer: customerId, source },
  showSourceDetail
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [sourceApiResponses, setSourceApiResponses] = useState([]);

  useEffect(
    () => {
      let config = {
        headers: Auth.getHeader()
      };

      const getCustomerInfo = () =>
        axios
          .get(URL + "/customers/customer_info/" + customerId, config)
          .then(response => {
            setCustomerInfo(response.data.data);
          })
          .catch(err => {
            console.error(err.response);
            Auth.isUnauthorized(err.response.status);
          });

      const getSourceApiResponses = () =>
        axios
          .get(URL + `/source_api_responses/${source.id}`, config)
          .then(({ data: { source_api_responses } }) =>
            setSourceApiResponses(source_api_responses)
          )
          .catch(err => {
            console.error(err.response);
            Auth.isUnauthorized(err.response.status);
          });

      Promise.all([getCustomerInfo(), getSourceApiResponses()]).finally(() =>
        setIsLoading(false)
      );
    },
    [source.id, customerId]
  );

  if (isLoading)
    return (
      <div className="animated fadeIn">
        <div className="preloader loader-small" />
      </div>
    );

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          Source Api Responses For {source.name} ({source.id})
        </div>
        <div className="row col-sm-12 mt-3">
          <div className="col-sm-11">
            <h5>{customerInfo.email}</h5>
          </div>
          <div className="col-sm-1">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => showSourceDetail(customerId)}
            >
              Back
            </button>
          </div>
          <div className="col-sm-12">
            <b>List of source api responses</b>
          </div>
        </div>
        <br />
        <div className="row col-sm-12">
          <div className="table-responsive">
            <table className="table striped">
              <thead>
              <tr>
                <th>Request</th>
                <th>Response Code</th>
                <th>Created At</th>
                <th>Raw Response Data</th>
              </tr>
              </thead>
              <tbody>
              {sourceApiResponses.map(
                ({
                   id,
                   request_method,
                   url,
                   response_status_code,
                   created_at,
                   response_body_url
                 }) => (
                  <tr key={id}>
                    <td>
                      {request_method} {url}
                    </td>
                    <td>{response_status_code}</td>
                    <td>{new Date(Date.parse(created_at)).toLocaleString()}</td>
                    <td>
                      {response_body_url ? (
                        <a
                          href={response_body_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click to view
                        </a>
                      ) : (
                        <span>
                          A link was not found. You can try again later or
                          attempt this query in the db:<br/>
                          <b>
                            select temp_response_body from source_api_responses
                            where id = {id}
                          </b>
                        </span>
                      )}
                    </td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  showSourceDetail: customer => {
    dispatch({
      type: "SOURCE_DETAIL",
      payload: customer
    });
  }
});

export default connect(null, mapDispatchToProps)(SourceApiResponses);

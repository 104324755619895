import React, {Component} from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavbarToggler,
  NavbarBrand,
  DropdownToggle
} from 'reactstrap';

import Auth from "../../libs/auth";

class Header extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      isLoggedIn: Auth.isLoggedIn()
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  handleLogout(e) {
    e.preventDefault();
    Auth.logout();
  }

  render() {
    const isLoggedIn = this.state.isLoggedIn;
    if (isLoggedIn) {
      return (
        <header className="app-header navbar">
          <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>&#9776;</NavbarToggler>
          <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>&#9776;</NavbarToggler>
          <NavbarBrand style={{ backgroundImage: 'none' }} href="#"><img src="/img/zenLedger.svg"/></NavbarBrand>
            <Nav className="ml-auto" navbar style={{ margin: '0 20px' }}>
              <NavItem>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle className="nav-link dropdown-toggle">
                    <span className="d-md-down-none">admin</span>
                  </DropdownToggle>
                  <DropdownMenu right className={this.state.dropdownOpen ? 'show' : ''}>
                    <DropdownItem onClick={this.handleLogout}><i className="fa-solid fa-lock" /> Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </Nav>

        </header>
      );
    } else {
      return (
         <header className="app-header navbar">
          <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>&#9776;</NavbarToggler>
          <NavbarBrand style={{ backgroundImage: 'none' }} href="#"><img src="/img/zenLedger.svg" /></NavbarBrand>
         </header>
      );
    }
  }
}

export default Header;

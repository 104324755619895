import React, {Component} from 'react';
import {connect} from 'react-redux'
import axios from "axios";
import moment from "moment";
import Auth from "../../libs/auth";

axios.defaults.withCredentials = true;
const SERVER = process.env.API_URL + '/admin/customers';
const CUSTOMER = process.env.API_URL + '/admin/customers/customer_info/';

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: props.storeData.current_customer,
      isLoading: true,
      customerInfo: null,
      irsForms: [],
      gua: [],
      formTypes: {
        irs_8949: "IRS 8949",
        irs_schedule_d: "Schedule D",
        irs_stolen_lost: "Stolen or Lost",
        irs_schedule_b: "Schedule B",
        irs_schedule_1: "Schedule 1",
        turbo_tax_zip: "H&R Block/Turbo Tax",
        tax_act_csv: "Tax Act",
        irs_schedule_a: "Schedule A",
        irs_8949_zip: "RS 8949 Zip",
      }
    }
  }

  componentDidMount() {
    this.fetchDownloads();
    this.getCustomerInfo();
  }

  getCustomerInfo = () => {
    let config = {
      headers: Auth.getHeader()
    };
    axios.get(CUSTOMER + this.state.customerId, config)
      .then((response) => {
        this.setState({
          customerInfo: response.data.data,
          isLoading: false
        });
      })
      .catch((err) => {
        console.error(err.response);
        Auth.isUnauthorized(err.response.status);
      });
  }

  fetchDownloads = () => {
    let config = {
      headers: Auth.getHeader()
    }
    axios.get(`${SERVER}/${this.state.customerId}/downloads`, config)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            isLoading: false,
            irsForms: response.data.data.irs_forms,
            gua: response.data.data.gua,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      });
  }

  renderGUARows = () => {
    const {gua} = this.state;
    if (gua.length === 0) return (<tr>
      <td colSpan={3}>No GUA have been downloaded</td>
    </tr>);
    return gua.map((item, index) => {
      return (
        <tr key={index}>
          <td>GUA</td>
          <td>{moment(item.downloaded_at).format("MMMM D, YYYY h:mm A")}</td>
        </tr>
      )
    })
  }

  renderIRSFormsRows = () => {
    const {irsForms, formTypes} = this.state;
    if (irsForms.length === 0) return (<tr>
      <td colSpan={3} className="text-center">No forms have been downloaded</td>
    </tr>);
    return irsForms.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.tax_year}</td>
          <td>{formTypes[item.name]} {item.part_number ? `Part ${item.part_number}` : ''}</td>
          <td>{moment(item.downloaded_at).format("MMMM D, YYYY h:mm A")}</td>
        </tr>
      )
    })
  }

  render() {
    const {
      isLoading
    } = this.state;
    const {customerInfo} = this.state;
    return (
      <div className="animated fadeIn">
        {isLoading ?
          <div className="preloader loader-small">
          </div>
          :
          <div>
            <div className="card">
              <div className="card-header">
                <i className="fas fa-edit"/> Downloads
                <div className="card-actions">
                  <a href="http://zenledger.io">
                    <small className="text-muted"></small>
                  </a>
                </div>
              </div>
              <div className="row col-sm-12 mt-3">
                <div className='col-sm-11'>
                  <h5>{_.get(customerInfo, 'email')}</h5>
                </div>
                <div className="col-sm-1">
                  <button className="btn btn-outline-primary btn-sm" onClick={() => this.props.showTaxDetail()}>
                    Back
                  </button>
                </div>
              </div>
              <br/>
              <div className="">
                <div className="row col-sm-12">
                  <table className='table table striped'>
                    <thead>
                    <tr>
                      <td colSpan={2}>
                        <b>GUA</b>
                      </td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <th>Downloaded At</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderGUARows()}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
            <div className="card">
              <div>
                <div className="row col-sm-12">
                  <table className='table table striped'>
                    <thead>
                    <tr>
                      <td colSpan={3}>
                        <b>Forms</b>
                      </td>
                    </tr>
                    <tr>
                      <th>Year</th>
                      <th>Name</th>
                      <th>Downloaded At</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderIRSFormsRows()}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        }
        <style jsx>{`
          .custom-select {
            width: 30%;
            min-width: 80px;
          }

          .subscription-form-td {
            max-width: 285px;
          }
        `}</style>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    showTaxDetail: (customer) => {
      dispatch({
        type: "ENABLE_CL",
        payload: customer
      })
    }
  };
};

export default connect(null, mapDispatchToProps)(Downloads);
